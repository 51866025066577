import React  from 'react';
import unverified from '../../photos/not.png'
import verifiedimg from '../../photos/ver.png'

function Result(props) {
  return (
          <div>
            <div className="card">
              {props.result.verified === "true" ? 
                <div>
                  <img src={verifiedimg} className="result-photo" alt="captured"></img>
                  <p>Identity verification completed successfully.</p>
                  {/* <p> الاسم : {props.result.fullName}</p> */}
                  <p> الرقم القومي : {props.result.idNum}</p>
                </div> 
                : 
                <div>
                <img src={unverified} className="result-photo"  alt="captured"></img>
                <p>Unsuccessful identity verification. Please submit a <a href="/" className="link">new request.</a></p>
                </div>
              }
            </div>
          </div>
    );
}

export default Result;