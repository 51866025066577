import React, { useState, useEffect } from 'react';
import Webcam from "react-webcam";
import BackDoc from '../backDoc/backDoc'
//import axios from 'axios';
import '../../App.css'

function WebCam (props) {
  const [face, setFace] = useState(props.videoDevices.length > 1 ? {exact : 'environment'} : 'user')
  const [mirrored, setMirrored ] = useState(props.videoDevices.length > 1 ? false : true);
  const [flip, setFlip] = useState(props.videoDevices.length > 1 ? {'transform' : 'none'} : {'transform': 'scaleX(-1)'})
  const [camera, setCamera] = useState(false);
  const [backDoc, setBackDoc] = useState(false);
  const [display, setDisplay] = useState(true);
  const [imgSrc, setImgSrc] = React.useState(null);
 // const [result, setResult] = React.useState(null);
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
  
  const webcamRef = React.useRef(null);
  const rect =() => {
    return setDisplay(false)
  }
  useEffect(() => {
    setScreenWidth(window.innerWidth)
  },[screenWidth])
  const capture = React.useCallback(() => {
    setCamera(true)
    const imageSrc = webcamRef.current.getScreenshot({width: 1920, height: 1080});
    setImgSrc(imageSrc);
  }, [webcamRef, setImgSrc]);

  const back = () => {
    setDisplay(true)
    setCamera(false)
  }

  const next = () => {
      // const data = {'file': imgSrc, 'screenWidth' : window.outerWidth, 'screenHeight': window.outerHeight}
      // axios.post('https://api.kyc.byonddigital.com/document', data).then(resp => {
      //   console.log(resp);
      //   setResult(resp.data)
      // });
    setBackDoc(true)
  };

  const changeCamera = ()=>{
    setFace(face === 'user' ? { exact: 'environment' } : 'user') 
    setMirrored(face === 'user' ? true : false);
    setFlip(face === 'user' ? {'transform': 'scaleX(-1)'} : {'transform':'none'})
  }

  return (
    <div>
      {backDoc ? <BackDoc videoDevices={props.videoDevices} frontImage = {imgSrc}></BackDoc> :
        <div>
          {camera ? <div>
          <div className="title">Captured photo.</div>
          {imgSrc && (
            <img 
              src={imgSrc} className="captured-photo" alt="captured" style={flip}
            />
          )}
          <div>
            <button onClick={back} type="button" className="btn btn-danger" style={{'marginTop': '10px'}}>Recapture?</button>
            <button onClick={next} type="button" className="btn btn-success" style={{'marginTop': '10px' , 'marginLeft': '10px'}}>Next</button></div> 
            <div className="text-center">  
              </div>
              </div>
              : <div> <div className="title">Please scan the <div className="title-focus">front side</div> of your ID.</div>
              <div aspectRatio={10 / 6}>
              <Webcam 
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/png"
                screenshotQuality="1"
                imageSmoothing	= {true}
                onUserMedia = {rect}
                mirrored={mirrored}
                aspectRatio = {10 / 6}
                className={display ? "" : "camera"}
                forceScreenshotSourceSize
                videoConstraints={{facingMode :face,
                  height: screenWidth >= 600 ?  0.75*screenWidth*0.6352941176 : 0.75*screenWidth,
                  width: screenWidth >= 600 ?  0.75*screenWidth :  0.75*screenWidth*0.6352941176,
                  aspectRatio : 10 / 6,
                  deviceId: props.videoDevices[props.videoDevices.length-1]
                }}
                height ={ screenWidth >= 600 ?  0.75*screenWidth*0.6352941176 : 0.75*screenWidth*0.6352941176}
                  width= {screenWidth >= 600 ?  0.75*screenWidth :  0.75*screenWidth*0.6352941176}
                >
              </Webcam>
              </div>
              <div>
                {props.videoDevices.length > 1 ? <button onClick={changeCamera} type="button" className="btn btn-secondary button" 
                                        hidden = {display}>Switch Camera</button> : null}
                <button onClick={capture} type="button" className="btn btn-danger button" style={{'marginLeft': '10px'}} 
                 hidden = {display}>Capture</button>
              </div>
              <div hidden={!display} className="spinner-border text-danger" role="status" >
                <span className="sr-only">Loading...</span>
              </div> 
              </div>
              }
        </div>
              }
      </div>
          );
  }

export default WebCam;