import React, { useState } from 'react';
import Webcam from "react-webcam";
import axios from 'axios';
import '../../App.css'
import Result from '../result/result';


function BackDoc(props) {
  const [face, setFace] = useState(props.videoDevices.length > 1 ? {exact : 'environment'} : 'user')
  const [ setFaceChange ] = useState({facingMode : face});
  const [mirrored, setMirrored ] = useState(props.videoDevices.length > 1 ? false : true);
  const [flip, setFlip] = useState(props.videoDevices.length > 1 ? {'transform' : 'none'} : {'transform': 'scaleX(-1)'})
  const [camera, setCamera] = useState(false);
  const [nextPic, setNextPic] = useState(false);
  const [imgSrc, setImgSrc] = React.useState(null);
  const [display, setDisplay] = useState(true);
  const [newresult, setNewResult] = React.useState(null);

  const webcamRef = React.useRef(null);

  const rect = () => {
    setDisplay(false)
  }
  const capture = React.useCallback(() => {
    setCamera(true)
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
  }, [webcamRef, setImgSrc])

  const back = () => {
    setDisplay(true)
    setCamera(false)
  }
  // useEffect(() => {
  //  // console.log(props.result)
  //   //setNewResult(props.result)
  
  // }, [props.result])
  const next = () => {
    const data = {'file': props.frontImage,'backFile': imgSrc}
    //const data = {'file': imgSrc}
    axios.post('https://api.kyc.byonddigital.com/document', data).then(resp => {
      setNewResult(resp.data)
    });
    setNextPic(true)
  }

  const changeCamera = ()=>{
      setFace(face === 'user' ? { exact: 'environment' } : 'user') 
      setFaceChange({facingMode: face})
      setMirrored(face === 'user' ? true : false);
      setFlip(face === 'user' ? {'transform': 'scaleX(-1)'} : {'transform':'none'});
    }

  return (
    <div>
      {nextPic  ? <div>{newresult === null ? <div className="text-center" style={{'marginTop':'150px'}}>
        <div className="spinner-border text-danger" role="status">
                <span className="sr-only ">Loading...</span>
        </div>
        </div> : 
        <Result result = {newresult}></Result>}
        </div>  :
      <div>
        {camera ? 
        <div>
          <div className="title">Captured photo.</div>
          {imgSrc && (
            <img 
              src={imgSrc} className="captured-photo" alt="captured" style={flip}
            />
            )}
              <div>
                <button onClick={back} type="button" className="btn btn-danger" style={{'marginTop': '10px'}}>Recapture?</button>
                <button onClick={next} type="button" className="btn btn-success" style={{'marginTop': '10px' , 'marginLeft': '10px'}}>Next</button></div>
              </div>
              : <div>
                  <div className="title">Please scan the <div className="title-focus">back side</div> of your ID.</div>
                  <Webcam 
                    audio={false}
                    ref={webcamRef}
                    className={display ? "" : "camera"}
                    screenshotFormat="image/png"
                    screenshotQuality="1"
                    imageSmoothing	= {true}
                    onUserMedia = {rect}
                    mirrored={mirrored}
                    videoConstraints={{facingMode :face,
                      height: window.outerWidth >= 600 ?  0.75*window.outerWidth*0.6352941176 : 0.75*window.outerWidth,
                      width: window.outerWidth >= 600 ?  0.75*window.outerWidth :  0.75*window.outerWidth*0.6352941176,
                      deviceId: props.videoDevices[props.videoDevices.length-1]
                    }}
                       height={window.outerWidth >= 600 ?  0.75*window.outerWidth*0.6352941176 : 0.75*window.outerWidth}
                    width={window.outerWidth >= 600 ?  0.75*window.outerWidth :  0.75*window.outerWidth*0.6352941176}
                    >
                  </Webcam>
                  <div>
                  {props.videoDevices.length > 1 ? <button onClick={changeCamera} type="button" className="btn btn-secondary button" 
                                          hidden = {display}>Switch Camera</button> : null}
                  <button onClick={capture} type="button" className="btn btn-danger button" style={{'marginLeft': '10px'}} 
                  hidden = {display}>Capture</button>
                  </div>
                  <div hidden={!display} className="spinner-border text-danger" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>      
                </div>
             }
       </div>
            }
    </div>
  );
}

export default BackDoc;