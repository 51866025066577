import React, {useState} from 'react'
import WebCam from './components/frontDoc/webCam'
import './App.css';
import logo from './photos/img.png'
import doc from './photos/12.jpeg'
import face from './photos/2.jpeg'
import data from './photos/4.jpeg'
import ver from './photos/3.jpeg'

function App() {
  const [scan, setScan] = useState(false);
  const [videoDevices] = React.useState([]);

  function startScan() {
    navigator.mediaDevices.enumerateDevices()
  .then(function(devices) {
    devices.forEach(function(device) {
      console.log(device.kind + ": " + device.label +
                  " id = " + device.deviceId);
      if(device.kind === "videoinput"){
        videoDevices.push(device.deviceId)
      }
    });
    setScan(true)
  })
  }
  
  return (
    <div>
      {scan ? 
              <div>
                  <div  style={{'borderBottom' : '0'}} >
                    <div className="card-header">
                      <img src={logo} style={{'width' : '100px', 'height': '40'}} alt="captured"></img>
                    </div> 
                    <div className="text-center" >
                      <WebCam videoDevices={videoDevices}></WebCam>
                    </div>
                  </div>
              </div>
              :
              <div className="card" >
                <div className="card-header">
                  <img src={logo} style={{'width' : '180px', 'height': '65'}}  alt="captured"></img>
                </div> 
                  <div className="card-body ">
                    <h5 className="card-title">B-Yond Identity verification solution is as simple as an ID and a selfie.</h5>
                    <br></br>
                    <div className="row">
                      <div className="col-sm-3">
                        <div className="card">
                          <div className="card-body ">
                            <h5 className="card-title" style={{'textAlign' : 'center'}}>ID Scanning.</h5>
                            <div className="text-center">
                              <img src={doc}  style={{'width' : '160px'}} alt="captured"></img>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="card">
                          <div className="card-body ">
                            <h5 className="card-title" style={{'textAlign' : 'center'}}>Face Capture.</h5>
                            <div className="text-center">
                              <img src={face}  style={{'width' : '160px'}}  alt="captured"></img>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="card">
                          <div className="card-body ">
                            <h5 className="card-title" style={{'textAlign' : 'center'}}>Verified.</h5>
                            <div className="text-center">
                              <img src={ver}  style={{'width' : '160px'}}  alt="captured"></img>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="card">
                          <div className="card-body ">
                            <h5 className="card-title" style={{'textAlign' : 'center'}}>Data Extracted.</h5>
                            <div className="text-center">
                              <img src={data}  style={{'width' : '160px'}} alt="captured"></img>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="App">
                      <br></br>
                        <button  type="button" className="btn btn-outline-danger " onClick={startScan}>Start Identity Verification</button>
                    </div>
                  </div>
                </div>}
              </div>
  );
}

export default App;
